<template>
  <div class="notWxInner-wrap">
    <background />
    <div class="title">欢迎使用{{ systemInfo.company }}</div>
    <div class="hasSend">
      <img src="@/assets/imgs/hook.png" alt />
    </div>
    <div class="des">
      <p>请您在电脑端登录账号使用{{ systemInfo.company }}</p>
      <p>方式一：复制网址到电脑端操作</p>
      <p>
        {{ systemInfo.hostPC }}&nbsp;&nbsp;
        <van-button type="info" @click="copy" size="mini">复制</van-button>
      </p>
      <p>方式二：电脑端浏览器搜索【{{ systemInfo.company }}】</p>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { Toast } from 'vant'

  export default {
    data() {
      return {
        imgSrc: '',
        url: 'https://saas.ecloudsign.com',
        adminList: [],
      }
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
    created() {
      this.comName = this.$route.query?.comName
    },
    methods: {
      back() {
        this.$router.back()
      },
      saveImg() {
        const aDom = document.createElement('a')
        aDom.setAttribute('download')
        aDom.href = this.imgSrc
        aDom.click()
      },
      copy() {
        const oInput = document.createElement('input')
        oInput.value = this.systemInfo.hostPC
        document.body.appendChild(oInput)
        oInput.select() // 选择对象
        document.execCommand('Copy') // 执行浏览器复制命令
        oInput.className = 'oInput'
        oInput.style.display = 'none'
        Toast('复制成功!')
      },
    },
  }
</script>
<style lang="less" scoped>
  .notWxInner-wrap {
    padding-top: 47px;
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 30px;
      text-align: center;
      margin-bottom: 19px;
    }
    .hasSend {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(17, 26, 52, 1);
      line-height: 21px;
      margin-bottom: 35px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 11px;
      }
    }
    .des {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(103, 114, 131, 1);
      text-align: center;
      line-height: 30px;
      margin-bottom: 20px;
      padding-left: 70px;
      p {
        text-align: left;
      }
    }
    .other-des {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0 36px;
      .desc-text {
        font-size: 14px;
        line-height: 30px;
        font-weight: 500;
        color: rgba(103, 114, 131, 1);
        text-align: center;
      }

      .back-button {
        margin-top: 16px;
        width: 108px;
      }
    }
    .qrCode {
      width: 200px;
      height: 200px;
      display: block;
      margin: 0 auto;
      margin-bottom: 63px;
    }
    .btn {
      width: 280px;
      height: 45px;
      background: rgba(22, 118, 255, 1);
      border-radius: 23px;
      margin: 0 auto;
      text-align: center;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 45px;
    }
  }
</style>
